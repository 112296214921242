import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Lehte ei leitud</h2>
          <p>Lehe aadress pole korrektne. Tagasi pealehele saab <Link to='/'>siit.</Link></p>
        </div>
      </header>
    </section>
  </Layout>
);

export default IndexPage;
